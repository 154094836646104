import * as React from 'react';
import FooterComponent from '../footer/footer';
import NavbarComponent from '../navbar/navbar';
import phoneIcon from "../../Images/PhoneIcon.png";
import emailIcon from "../../Images/MailboxIcon.png";
import addressIcon from "../../Images/AddressIcon.png";
import { Card, CardMedia,CardContent } from "@mui/material";

const ContactUsComponent = (props: any) => {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1200);
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 1200;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile])
    return (
        <div className="main-body">
            <NavbarComponent />
            <div className="hero--text textColorBlack marginTop50"><h1 className="h10 hero--header">Contact Us</h1></div>
            <div className="hero--text textColorBlack"><span className="missionText fontSize33 colorBrown">Get in Touch With Us! We'd Love to Hear From You!</span></div>
            <section className='section'>
            <div className="columns textCenter">
                <div className="column">
                        <Card className="borderNone">
                            <CardMedia
                                component="img"
                                alt="Phone Icon"
                                height="100%"
                                image={phoneIcon}/>
                                <CardContent> <span className='missionText fontSize29 fontsizeResponsiveContactUs'>732-354-1881</span></CardContent>
                        </Card>
                </div>
                <div className="column">
                    <Card className="borderNone">
                        <CardMedia
                            component="img"
                            alt="Email Icon"
                            height="100%"
                            image={emailIcon}
                        /><CardContent> 
                            <a className="missionText fontSize29 fontsizeResponsiveContactUs" href="mailto:info@aashraycharities.org">info@aashraycharities.org</a><br/>
                            <a className="missionText fontSize29 fontsizeResponsiveContactUs" href="mailto:info@aashraycharities.org">aashraycharities@gmail.com</a>
                        </CardContent>
                    </Card>
                </div>
                <div className="column">
                <Card className="borderNone">
                <CardMedia
                    component="img"
                    alt="Address Icon"
                    height="100%"
                    image={addressIcon}
                /><CardContent> <span className='missionText fontSize29 fontsizeResponsiveContactUs'>704 Blossom Circle, Dayton NJ 08810</span></CardContent>
                </Card>
                </div>
            </div>
            </section>
            <div className="marginSpace"></div>
            <FooterComponent />
        </div >
    )
}
export default ContactUsComponent;